import React from 'react'
import PropTypes from 'prop-types'

const ProjectItem = (props) => {
  const { project } = props
  return (
    <a className='project' href={project.link}>
      {project.acf.image && (
        <img
          className='project__image'
          src={project.acf.image.sizes.thumbnail}
          width={project.acf.image.sizes['thumbnail-width']}
          height={project.acf.image.sizes['thumbnail-height']}
          alt={project.title.rendered}
          title={project.title.rendered}
        />
      )}
      {project.acf.subheading && (
        <span className='project__subheading'>{project.acf.subheading}</span>
      )}
      <h4 className='project__heading'>{project.title.rendered}</h4>
    </a>
  )
}

ProjectItem.propTypes = {
  project: PropTypes.object.isRequired
}

export default ProjectItem
