import React from 'react'
import PropTypes from 'prop-types'

import EmployeeItem from './EmployeeItem'

const EmployeesList = (props) => {
  const { employees, filter } = props
  let employeesToDisplay = employees
  if (filter !== false) {
    employeesToDisplay = employees.filter(
      (employee) => employee.acf.category.indexOf(filter) !== -1
    )
  }

  return (
    <>
      {employeesToDisplay.map((employee) => (
        <React.Fragment key={employee.id}>
          <EmployeeItem key={employee.id} employee={employee} className='employee' />
        </React.Fragment>
      ))}
    </>
  )
}

EmployeesList.propTypes = {
  employees: PropTypes.array.isRequired,
  filter: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

EmployeesList.defaultProps = {
  filter: false
}

export default EmployeesList
