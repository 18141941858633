import React from 'react'
import PropTypes from 'prop-types'

import ProjectItem from './ProjectItem'

const ProjectList = (props) => {
  const { projects, filter } = props
  let projectsToDisplay = projects
  if (filter !== false) {
    projectsToDisplay = projects.filter((project) => project.acf.category.indexOf(filter) !== -1)
  }

  return (
    <>
      {projectsToDisplay.map((project) => (
        <React.Fragment key={project.id}>
          <ProjectItem key={project.id} project={project} className='project' />
        </React.Fragment>
      ))}
    </>
  )
}

ProjectList.propTypes = {
  projects: PropTypes.array.isRequired,
  filter: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

ProjectList.defaultProps = {
  filter: false
}

export default ProjectList
