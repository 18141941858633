import React from 'react'
import PropTypes from 'prop-types'

const EmployeeItem = (props) => {
  const { employee } = props
  return (
    <div className='employee'>
      {employee.acf.image && (
        <img
          className='employee__image'
          src={employee.acf.image.sizes.thumbnail}
          width={employee.acf.image.sizes['thumbnail-width']}
          height={employee.acf.image.sizes['thumbnail-height']}
          alt={employee.title.rendered}
          title={employee.title.rendered}
        />
      )}
      <div className='employee__details'>
        <span className='employee__job'>{employee.acf.details.job}</span>
        <h4 className='employee__name'>{employee.title.rendered}</h4>
        <span className='employee__description'>{employee.acf.details.description}</span>
        {employee.acf.details.email && (
          <span className='employee__email'>
            <a href={`mailto:${employee.acf.details.email}`}>{employee.acf.details.email}</a>
          </span>
        )}
      </div>
    </div>
  )
}

EmployeeItem.propTypes = {
  employee: PropTypes.object.isRequired
}

export default EmployeeItem
